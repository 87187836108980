export const faqsData = [
  {
    title:
      "How do you build the syllabus of the Content Writing & Social Media Launchpad?",
    category: "general",
    description: `<p>Kraftshala’s online content writing course syllabus is a synthesis of a series of discussions, inspirations, experiences, and expectations from the top branding, social media, content writing and copywriting experts. We start from the content marketing roles and social media roles that we are targeting for our students and work our way backwards to focus and train you on only those skills which are relevant for you to become a top-notch content writer and social media marketer in today’s digital age.<br/><br/> Our academics team partners with hiring managers and subject matter experts from leading companies to figure out the skills to be demonstrated at the workplace. To build full proficiency, we have a strong feedback loop where the data on candidates’ performance in the course and during the placement process goes into improving the learning experience to make our syllabus highly relevant to the roles of today!</p>`,
  },
  {
    title: "Can I self pace the program?",
    category: "general",
    description:
      "Partly. The schedule of the social media and content writing course allows students to learn and practice at their own pace. Its been designed for working professionals and campus students and just requires you to spend 6-8 hours per week. You will have live concept and feedback sessions 1-2 times a week. Do speak to the admissions team about your schedule and the commitments the program will need before coming on board. ",
  },
  // {
  //   title:
  //     "Can I withdraw from the content writing and social media marketing course after I receive my MBA entrance result?",
  //   category: "general",
  //   description: `<p>If you have plans of pursuing higher education within the next 2 years, we would recommend that you don't apply for the program. The program is focused on getting you high potential roles without an MBA and in case you need more help in making that decision and understanding the career trajectories, you can discuss this with your counselor. We will be happy to help, irrespective of whether you take the program or not!</p>`,
  // },
  {
    title:
      "Will I get a certificate after completing the content writing course?",
    category: "general",
    description:
      "Yes, the Content Writing & Social Media Launchpad Program is an online social media marketing and content writing course with certification. It’s a milestone based program which will require you to clear all benchmarks to be eligible for moving to the next stage in the program and eventually get INDUSTRYCreds™ Certification. The Kraftshala certification is recognized by top companies in the industry and will help you stand out.",
  },
  {
    title:
      "Is attendance mandatory? What if I miss any content writing class? Will I get a make-up class? Or a recorded version of the class?",
    category: "general",
    description:
      "Yes, all Kraftshala programs require you to maintain your attendance and on-time submissions >80%. You will learn and succeed only when you are committed to the process and thus this is a crucial part of your success. In case of any exceptions, we will ensure that you get a recording - but you will need to get prior approval from the Kraftshala team to do that.",
  },

  {
    title:
      "What is the eligibility criteria for Kraftshala’s online content writing course with placement?",
    category: "eligibility",
    description:
      "While a basic requirement is for you to be a passout from class 12th / graduation, what really matters is your ability to learn. The Kraftshala Screening process thus is a fun 40-min exercise where we teach you a concept on how to decode an ad and then you are asked to apply that in the test. ",
  },
  // {
  //   title:
  //     "I have just taken my final exams and have not received my degree yet. Can I still apply?",
  //   category: "eligibility",
  //   description: `<p>Yes. You can discuss this with your Admissions Counselor. To be eligible for the program, you need to be available for the next 15 weeks for full-time social media, copywriting & content writing training & then full-time employment after completion of the program.
  //   </p>`,
  // },
  // {
  //   title: "What do I do if I don't have a Pan Card/Aadhaar Card?",
  //   category: "eligibility",
  //   description: `<p>At the time of admission, having an Aadhaar / PAN Card is mandatory. This is because you will get a conditional loan approval with our EMI partner for the scenario under which after receiving the job offer of 4.5L or more, you want to pay the fee by taking a loan.</p>`,
  // },
  {
    title: "What's the selection process like?",
    category: "admission",
    description: `<p><b>Step 1: </b>Fill up the application form and get your process started.
    </p><p><b>Step 2:</b> There are 2 parts here </p><p>
     <ol><li><b>Free Screening Test-</b> This is a 60 min test that evaluates you on your ability to grasp concepts and think logically. This has been created in discussion with top experts and has proven to be highly successful in ensuring we assemble a batch of high potential candidates.Give <a class="page-links" target="_blank" href="https://go.kraftshala.com/practice_screening_test"> practice test here.</a></li>
    <br/><li><b>Career Chat with Admissions team -</b> You also speak to the Admissions Team at this stage to get your doubts clarified and also to know more about the program and the tracks. </li>
    </ol> </p>
   <p> <b>Step 3:</b>  Within 3-4 working days, you will get your results. And if accepted, you can complete the payment formalities and enroll and start your journey towards a fantastic career! </p>`,
  },
  // {
  //   title: "What is the Kraftshala Changemakers scholarship?",
  //   category: "admission",
  //   description: `<p>Kraftshala Changemakers Scholarships will be given to folks who do exceptionally well in the admission process. Since they will only be rolled out to 5% of the batch, we will look at a bunch of things - Screening Test scores, your intent towards Kraftshala and how you can contribute to our alumni community. This is based on your first attempt of the Screening Test, if given within 72 hours of submitting your application.
  //   <br/><br/>If you get the Scholarship based on your Screening Test results, you are eligible for a fee waiver of 30% on your digital marketing course fees. Please note that if you get enrolled via the Scholarship, you will not be eligible for a refund. The Scholarship discount cant be combined with any other discount (e.g. Propel Discount).`,
  // },
  // {
  //   title: "When will I get to know whether I am getting the scholarship or not?",
  //   category: "admission",
  //   description: `<p>You will get to know within 3-4 days after giving your Screening Test.</p>`,
  // },
  // {
  //   title:
  //     "What do you look for in candidates when they apply to Kraftshala’s social media, copywriting and content writing certification course?",
  //   category: "admission",
  //   description: `<p>Kraftshala’s Content Writing & Social Media Launchpad is a very immersive and fast-paced program.<br/>
  //   Marketing is a field which requires you to think about the users and continuously solve their problems through your product, campaigns, content, and communication. The candidates who succeed with Kraftshala are the ones who have:
  //  <br/><ul><li>Initiative</li>
  //  <li>Resilience</li>
  //  <li>Ambition</li>
  //  <li>Problem Solving</li></ul>
  //  </p><p>
  //  So if you have the intent to build a content writing and social media marketing career and are willing to go through the process with us for the next 15 weeks, we would love to meet you.
  //   </p>`,
  // },

  {
    title:
      "After completing the online content writing and social media marketing course, what kind of job roles can I expect to find?",
    category: "placement",
    description: `<p>If you have taken up the placement track, you get access to Kraftshala’s proprietary placement process and portal which has successfully placed ~2000 students in amazing brands and agencies so far,<br/>You can check out the detailed placement report of our previous batches  <a class="page-links" href="https://placement-reports.kraftshala.com/" target="_blank">here</a></p>
    <p>You can expect</p>
    <ul>
      <li>Roles which are a mix of both strategy and execution</li>
      <li>Companies are a mix of rapidly growing companies, traditional multinationals and both big and medium sized agencies</li>
      <li>Examples of a few roles - Social Media Specialist, Creative Writing & Content Marketing, Influencer Marketing Specialist, Brand Associate, ORM Specialist, SEO Analyst.</li>
    </ul>
    <p>Placements for the content and social media specialization is close to 100% due to the very high demand of such roles.</p>
  `,
  },
  {
    title:
      "Is the Content Writing and Social Media Launchpad a job guarantee content writing course?",
    category: "placement",
    description: `<p>Content Writing and Social Media Launchpad is not a job-guarantee program. If you have taken the placement track, you do get the best shot at cracking an amazing role like 2000+ alumni.</p>
     <p>You can check out the detailed placement report of our previous batches <a class="page-links" href="https://placement-reports.kraftshala.com/" target="_blank">here</a></p>
    <p>We are the largest in terms of <b>actual verifiable placements </b>(which means full-time jobs, not side projects or freelancing gigs). With a 98% placement rate, we are also far ahead than anyone else when it comes to placement rates. </p>
    <p>We’re also the only ones publishing placement reports (although we would love for more educators to join us). We don't need to do this, but student outcomes have remained the north star metric for us from the beginning. Hence, we are built to dedicate every last resource to helping you get the opportunities you deserve.</p>`,
  },
  {
    title: "When do the placements start if I take up the placement track?",
    category: "placement",
    description: `<p>The placement process begins upon the completion of your course and additional CV and Interview Prep which could last for 3-4 more weeks after the completion of the 16 weeks of training. You move to the placements after clearing the tests and interviews as outlined by the Assessment Center. </p>`,
  },
  // {
  //   title: "How long does it take to get placed?",
  //   category: "placement",
  //   description: `<p>Placement drives typically last for 8 weeks. It can take longer to finish for students who are struggling to reach the requisite skill level, but more than 98% students thus far have cracked a job offer of 4.5 LPA or more.</p>`,
  // },
  {
    title:
      "If I take up the placement track do I directly get a job of 4.5L+ or do I get to do an internship first?",
    category: "placement",
    description: `<p>You get both kinds of roles as a part of the placement track. You get a fixed number of passess which you can use for passing a given role.  You also get all the details of the company and role beforehand which you use to decide whether to apply or not.Whether the candidate then gets an internship or a full-time offer depends on the company’s requirements, internal process and the candidate's performance in the interview.
      </p><p>
    Thus far, 50% of our students have gotten direct roles post completion of their training and majority of the folks who go into the internships convert it into a full time role of 4.5L or more.
    </p><p>
    The remaining students who are unable to convert their internship are brought back into the process.</p>`,
  },
  {
    title: "Am I supposed to apply for all jobs that come in?",
    category: "placement",
    description: `<p>No. You get a certain amount of passes which you can utilize to let an opportunity pass. All the information about the company is made available before you apply. Once applied, you should not back out as it impacts the chances of everyone else as well.</p>`,
  },
  // {
  //   title: "If I get an internship first, will that have a stipend?",
  //   category: "placement",
  //   description: `<p>Mostly Yes. But we will prioritize your roles and the kind of exposure you get during internship. Please note that the final call on the internship duration would depend on the company you intern with.     </p>`,
  // },
  {
    title:
      "What if I don't want to move cities? Can I get a job in one specific city itself?",
    category: "placement",
    description: `<p>If you have a constraint to be in one of the following cities for your placements (Delhi NCR, Mumbai, Bangalore), then in addition to the placement track, you can opt for our Location Specific model once you get selected for Kraftshala’s content writing course. You can make this decision at the time of enrolment or before moving to placements.
   <br/><br/>
      Within this, you will have to pay an additional amount of ₹17,000 + GST. This fee is to account for the additional effort that the Kraftshala team will put in to bring in more companies operating in a given location. 
     </p>`,
  },
  // {
  //   title: "What are the terms and conditions for a refund?",
  //   category: "fee",
  //   description: `<p>You’re eligible for a refund when you are not able to get a job that pays you ₹ 4.5 LPA or above within 15 months of the digital marketing course completion. In this case, we refund 60% of your fee.
  //   <br/><br/>
  //   Any refund of fees will be as per the T&Cs outlined. You will get access to this by speaking to your counselor and also before making the decision to enroll in the program</p>`,
  // },

  {
    title:
      "Will I get a refund if I don’t want to continue with the content writing and social media marketing course?",
    category: "fee",
    description: `<p>No. The program has a non-refundable fee structure. </p>`,
  },
  {
    title:
      "I want to take the program, but I do not want to go for a job. How is the fee calculated in that case?",
    category: "fee",
    description: `<p>You can choose only for the learning and upskilling track at the lower fees in case you are not interested in going for placements. If you later change your mind, you can opt for the placement track after completing your training as well.</p>`,
  },
];
