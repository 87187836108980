import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import "./styles.scss";

const index = () => {
  return (
    <div className="career-trajectory">
      <h2 className="career-trajectory-sub-title">CAREER TRAJECTORY</h2>
      <div className="career-trajectory-header">
        Here’s what your days..months..years can look like in 2025 and beyond!
        <img
          className="calenderIcon"
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86y1t7m1yl2dvw.png"
          loading="lazy"
        />
        <br />
      </div>
      <div className="new-underline">
        <StaticImage src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png" />
      </div>
      <div className="career">
        <img
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a6vjtw.png"
          className="dottedBg"
          loading="lazy"
        />
        <div className="column">
          <div className="career-card">
            <div className="title">
              <div className="indexNo">01</div>
              <span className="title ml-mob-1">Content Writer</span>
            </div>
            <div className="desc">
              Write content to drive more folks to your website
            </div>
          </div>
          <div className="career-card">
            <div className="title alignTop">
              <div className="indexNo">01</div>
              <span className="ml-mob-1">Social Media</span>
            </div>
            <div className="desc">
              Build reach and engagement on social channels
            </div>
          </div>
          <div className="career-card">
            <div className="title">
              <div className="indexNo">01</div>Copy
              <br className="pc-hide" />
              writer
            </div>
            <div className="desc">Create ads that convert users</div>
          </div>
        </div>
        <img
          className="mobDotted"
          src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a9vr5a.png"
          loading="lazy"
        />
        <div className="flex">
          <div className="career-card">
            <div className="title">
              <div className="indexNo index-2">02</div>Marketing Manager
            </div>
            <div className="desc">
              Plan and execute strategies to drive results through your team
            </div>
          </div>
          <img
            className="mobDotted2"
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a9x9iu.png"
            loading="lazy"
          />

          <div className="career-card lastTwo">
            <div className="title">
              <div className="indexNo">03</div>Brand Head
            </div>
            <div className="desc">
              Build and execute strategies to help all organic marketing
              channels work together
            </div>
          </div>
          <img
            className="mobDotted2"
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a9x9iu.png"
            loading="lazy"
          />
          <img
            className="mobDotted2"
            src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2a9x9iu.png"
            loading="lazy"
          />
          <div className="career-card yellowBorder">
            <div className="title alignTop">
              <div className="indexNo">04</div>Marketing Head
            </div>
            <div className="desc">Drive business results profitably</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default index;
