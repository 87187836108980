import React from 'react';
import './styles.scss';
import {Data} from './data.js';
import Slider from 'react-slick';

const CourseFor = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 1000,
    autoplaySpeed: 4000,
    fadeIn: false,
    autoplay: false,
    pauseOnHover: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 805,
        settings: {
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div className="mlp-main-placements mlp-main basl-main-placements csm-main-wrapper">
      <div className="placements-container basl-placement-container">
        <div className="bsl-alumni-header">
        
          <h2 className="main-header">
            Who is the course for?
          </h2>
          <h2 className="main-header-mob">
            Who is the course for?
          </h2>
          <div className="new-underline-csm">
            <img src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/453b391a-01f1-4431-a281-8c58470d35bc/events/banner-upload/14i86yzpcm2aafvlc.png" loading="lazy" className="alumni-underline csm-mob-underline-alumini" />
          </div>
       
        </div>
        <div className="placement-slider csm-bsl-cards-wrapper">
          <Slider {...settings}>
            {Data?.map((courseCard, index) => (
              <div className={`csm-bsc-cards csm-bsc-${index}`} key={index}>
                <div className='image-container'>
                  <img src={courseCard.image} alt={courseCard.title}/>
                  <h3 className='Image-heading'>{courseCard.title}</h3>
                </div>
                <div className='text-container'>
                  <ul dangerouslySetInnerHTML={{ __html: courseCard.desc }}></ul>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
         
  );
};

export default CourseFor;